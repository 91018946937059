import React from "react";
import Link from "gatsby-link";
import SEO from "../components/seo";
import "./home.scss";

const IndexPage = (props) => (
    <div location={props.location}>
        <SEO title="Home" language="en"/>
        <div className="homepage">
            <div className="container">
                <h1 className="homepage__title">
                    We are Bat in Web. We are in the internet. <br/>
                    We're perfectly weaving the Web. <br/>
                    We have a Web on the neck!
                </h1>
                <p className="homepage__subtitle">
                    With passion, we implement interactive projects<br/>
                    by implementing <strong>innovative</strong> technology,<br/>
                    that transforms into <strong>effects</strong>
                </p>
                <Link to="/en/contact-us/" className="btn btn__action">Get offer you need</Link>
            </div>
        </div>
    </div>
);

export default IndexPage;
